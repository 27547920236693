// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-about-page-index-js": () => import("./../../../src/templates/AboutPage/index.js" /* webpackChunkName: "component---src-templates-about-page-index-js" */),
  "component---src-templates-contact-page-index-js": () => import("./../../../src/templates/ContactPage/index.js" /* webpackChunkName: "component---src-templates-contact-page-index-js" */),
  "component---src-templates-home-page-index-js": () => import("./../../../src/templates/HomePage/index.js" /* webpackChunkName: "component---src-templates-home-page-index-js" */),
  "component---src-templates-legals-page-index-js": () => import("./../../../src/templates/LegalsPage/index.js" /* webpackChunkName: "component---src-templates-legals-page-index-js" */),
  "component---src-templates-newsletter-page-index-js": () => import("./../../../src/templates/NewsletterPage/index.js" /* webpackChunkName: "component---src-templates-newsletter-page-index-js" */),
  "component---src-templates-product-page-index-js": () => import("./../../../src/templates/ProductPage/index.js" /* webpackChunkName: "component---src-templates-product-page-index-js" */)
}

