// import 'babel-polyfill'
import 'babel-preset-gatsby'
import React from 'react'
import { RecoilRoot } from 'recoil'
import objectFitImages from 'object-fit-images'
import './src/styles/index.scss'

export const onInitialClientRender = () => {
  objectFitImages()
}

export const wrapRootElement = ({ element }) => {
  return (
    <RecoilRoot>
      {element}
    </RecoilRoot>
  )
}

export const shouldUpdateScroll = () => {
  return false
}

export const onClientEntry = () => {
  if (!(`IntersectionObserver` in window)) {
    require(`intersection-observer`)
    console.log(`# IntersectionObserver is polyfilled!`)
  }
}

